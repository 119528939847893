import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";

export interface ICVTextEdit {
  open: boolean;
  closed: (item: string) => void;
  item?: string;
}

const CVTextEdit: React.FC<React.PropsWithChildren<ICVTextEdit>> = (props) => {
  const [text, setText] = React.useState("");
  const textHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleCloseCancel = () => {
    if (props.item) {
      setText(props.item);
      props.closed(props.item);
    }
  };

  const handleCloseSave = () => {
    if (props.item) {
      props.closed(text);
    }
  };

  useEffect(() => {
    if (props.item) {
      setText(props.item);
    }
  }, [props.item]);

  return (
    <div>
      <Dialog open={props.open} onClose={handleCloseCancel}>
        <DialogTitle>Edit Text</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit the content and save your changes!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Text"
            type="text"
            fullWidth
            variant="standard"
            value={text}
            onChange={textHandleChange}
            placeholder="Set your text..."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancel}>Cancel</Button>
          <Button onClick={handleCloseSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CVTextEdit;
