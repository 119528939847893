import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { ICVUserContact, ICVUserContactsItem } from "../../db";

export interface ICVContactEdit {
  open: boolean;
  cancel: () => void;
  confirm: (item: ICVUserContact) => void;
  item?: ICVUserContact;
}

const CVContactEdit: React.FC<React.PropsWithChildren<ICVContactEdit>> = (
  props
) => {
  // PHONE
  const [checkedPhone, setCheckedPhone] = React.useState(true);
  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedPhone(event.target.checked);
    setPhone({ enabled: event.target.checked, value: phone.value });
  };
  const [phone, setPhone] = React.useState<ICVUserContactsItem>({
    enabled: true,
    value: "",
  });
  const phoneHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone({ enabled: true, value: event.target.value });
  };
  // EMAIL
  const [checkedEmail, setCheckedEmail] = React.useState(true);
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedEmail(event.target.checked);
    setEmail({ enabled: event.target.checked, value: email.value });
  };
  const [email, setEmail] = React.useState({ enabled: true, value: "" });
  const emailHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail({ enabled: true, value: event.target.value });
  };
  // LINKEDIN
  const [checkedLinkedin, setCheckedLinkedin] = React.useState(true);
  const handleChangeLinkedin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedLinkedin(event.target.checked);
    setLinkedin({ enabled: event.target.checked, value: linkedin.value });
  };
  const [linkedin, setLinkedin] = React.useState({ enabled: true, value: "" });
  const linkedinHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLinkedin({ enabled: true, value: event.target.value });
  };
  // FACEBOOL
  const [checkedFacebook, setCheckedFacebook] = React.useState(true);
  const handleChangeFacebook = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedFacebook(event.target.checked);
    setFacebook({ enabled: event.target.checked, value: facebook.value });
  };
  const [facebook, setFacebook] = React.useState({ enabled: true, value: "" });
  const facebookHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFacebook({ enabled: true, value: event.target.value });
  };
  // INSTAGRAM
  const [checkedInstagram, setCheckedInstagram] = React.useState(true);
  const handleChangeInstagram = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedInstagram(event.target.checked);
    setInstagram({ enabled: event.target.checked, value: instagram.value });
  };
  const [instagram, setInstagram] = React.useState({
    enabled: true,
    value: "",
  });
  const instagramHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInstagram({ enabled: true, value: event.target.value });
  };
  // TWITTER
  const [checkedTwitter, setCheckedTwitter] = React.useState(true);
  const handleChangeTwitter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTwitter(event.target.checked);
    setTwitter({ enabled: event.target.checked, value: twitter.value });
  };
  const [twitter, setTwitter] = React.useState({ enabled: true, value: "" });
  const twitterHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTwitter({ enabled: true, value: event.target.value });
  };
  // LOCATION
  const [checkedLocation, setCheckedLocation] = React.useState(true);
  const handleChangeLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedLocation(event.target.checked);
    setLocation({ enabled: event.target.checked, value: location.value });
  };
  const [location, setLocation] = React.useState({ enabled: true, value: "" });
  const locationHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocation({ enabled: true, value: event.target.value });
  };

  const handleCloseCancel = () => {
    if (props.item) {
      setPhone(props.item?.phone);
      setEmail(props.item?.email);
      setLinkedin(props.item?.linkedin);
      setFacebook(props.item?.facebook);
      setInstagram(props.item?.instagram);
      setTwitter(props.item?.twitter);
      setLocation(props.item?.location);
    }
    props.cancel();
  };

  const handleCloseSave = () => {
    if (props.item) {
      props.confirm({
        phone,
        email,
        linkedin,
        facebook,
        instagram,
        twitter,
        location,
      });
    }
  };

  useEffect(() => {
    if (props.item) {
      setPhone(props.item.phone);
      setEmail(props.item.email);
      setLinkedin(props.item.linkedin);
      setFacebook(props.item.facebook);
      setInstagram(props.item.instagram);
      setTwitter(props.item.twitter);
      setLocation(props.item.location);
    }
  }, [JSON.stringify(props.item)]);

  return (
    <div>
      <Dialog open={props.open} onClose={handleCloseCancel}>
        <DialogTitle>Edit Contact Infos</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit your contact details and save your changes!
          </DialogContentText>
          <Box display="flex" alignItems="flex-end">
            <TextField
              autoFocus
              margin="dense"
              id="phone"
              label="Phone number"
              type="text"
              fullWidth
              variant="standard"
              value={phone.value}
              onChange={phoneHandleChange}
              placeholder="Set your phone number..."
            />
            <Checkbox
              checked={checkedPhone}
              onChange={handleChangePhone}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Box display="flex" alignItems="flex-end">
            <TextField
              margin="dense"
              id="email"
              label="E-Mail address"
              type="text"
              fullWidth
              variant="standard"
              value={email.value}
              onChange={emailHandleChange}
              placeholder="Add your email address..."
            />
            <Checkbox
              checked={checkedEmail}
              onChange={handleChangeEmail}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Box display="flex" alignItems="flex-end">
            <TextField
              margin="dense"
              id="linkedin"
              label="Linkedin address"
              type="text"
              fullWidth
              multiline
              maxRows={4}
              variant="standard"
              value={linkedin.value}
              onChange={linkedinHandleChange}
              placeholder="Add a project description..."
            />
            <Checkbox
              checked={checkedLinkedin}
              onChange={handleChangeLinkedin}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Box display="flex" alignItems="flex-end">
            <TextField
              margin="dense"
              id="facebook"
              label="Facebook link"
              type="text"
              fullWidth
              variant="standard"
              value={facebook.value}
              onChange={facebookHandleChange}
              placeholder="Add your facebook link..."
            />
            <Checkbox
              checked={checkedFacebook}
              onChange={handleChangeFacebook}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>

          <Box display="flex" alignItems="flex-end">
            <TextField
              margin="dense"
              id="instagram"
              label="Instagram link"
              type="text"
              fullWidth
              variant="standard"
              value={instagram.value}
              onChange={instagramHandleChange}
              placeholder="Add your instagram link..."
            />
            <Checkbox
              checked={checkedInstagram}
              onChange={handleChangeInstagram}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Box display="flex" alignItems="flex-end">
            <TextField
              margin="dense"
              id="twitter"
              label="Twitter link"
              type="text"
              fullWidth
              variant="standard"
              value={twitter.value}
              onChange={twitterHandleChange}
              placeholder="Add your twitter link..."
            />
            <Checkbox
              checked={checkedTwitter}
              onChange={handleChangeTwitter}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Box display="flex" alignItems="flex-end">
            <TextField
              margin="dense"
              id="location"
              label="Location link"
              type="text"
              fullWidth
              variant="standard"
              value={location.value}
              onChange={locationHandleChange}
              placeholder="Add your location link..."
            />
            <Checkbox
              checked={checkedLocation}
              onChange={handleChangeLocation}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCloseSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CVContactEdit;
