import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { ICVUserJobs } from "../../db";

export interface ICVJobsEdit {
  open: boolean;
  cancel: () => void;
  confirm: (item: ICVUserJobs) => void;
  item?: ICVUserJobs;
}

const CVJobsEdit: React.FC<React.PropsWithChildren<ICVJobsEdit>> = (props) => {
  const [date, setDate] = React.useState("");
  const dateHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };
  const [project, setProject] = React.useState("");
  const projectHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProject(event.target.value);
  };
  const [description, setDescription] = React.useState("");
  const descriptionHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };
  const [tasks, setTasks] = React.useState("");
  const tasksHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTasks(event.target.value);
  };

  const handleCloseCancel = () => {
    if (props.item) {
      setDate(props.item?.date);
      setProject(props.item?.project);
      setDescription(props.item?.description);
      setTasks(props.item?.tasks);
    }
    props.cancel();
  };

  const handleCloseSave = () => {
    if (props.item) {
      props.confirm({
        uuid: props.item.uuid,
        date: date,
        project: project,
        description: description,
        tasks: tasks,
      });
    }
  };

  useEffect(() => {
    if (props.item) {
      setDate(props.item?.date);
      setProject(props.item?.project);
      setDescription(props.item?.description);
      setTasks(props.item?.tasks);
    }
  }, [JSON.stringify(props.item)]);

  return (
    <div>
      <Dialog open={props.open} onClose={handleCloseCancel}>
        <DialogTitle>Edit Job Infos</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit your project details and save your changes!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Project Date"
            type="text"
            fullWidth
            variant="standard"
            value={date}
            onChange={dateHandleChange}
            placeholder="Set your project date..."
          />
          <TextField
            margin="dense"
            id="title"
            label="Project Title"
            type="text"
            fullWidth
            variant="standard"
            value={project}
            onChange={projectHandleChange}
            placeholder="Add a project title..."
          />
          <TextField
            margin="dense"
            id="description"
            label="Project Description"
            type="text"
            fullWidth
            multiline
            maxRows={4}
            variant="standard"
            value={description}
            onChange={descriptionHandleChange}
            placeholder="Add a project description..."
          />
          <TextField
            margin="dense"
            id="tasks"
            label="Project Tasks"
            type="text"
            fullWidth
            variant="standard"
            value={tasks}
            onChange={tasksHandleChange}
            placeholder="Add your project tasks..."
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCloseSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CVJobsEdit;
