import { Box, Paper, Typography } from "@mui/material";
import React from "react";

export interface IPortfolioFooter {}

const PortfolioFooter: React.FC<React.PropsWithChildren<IPortfolioFooter>> = (
  props
) => {
  return (
    <Paper elevation={2} style={{ height: "100%", padding: "1rem" }}>
      <Box display="flex" justifyContent="flex-end">
        <Typography>Created by Gregor Kurpiel</Typography>
      </Box>
    </Paper>
  );
};

export default PortfolioFooter;
