import { useEffect, useState } from "react";
import { ICVUser } from "../db";

const useLocalStorage = (key: string, initialValue: { user: ICVUser }) => {
  const [value, setValue] = useState(() => {
    const item = window.localStorage.getItem(key);
    console.log("GKU LS ITEM", item, initialValue);
    return item ? JSON.parse(item) : initialValue.user;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
