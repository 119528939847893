import EditIcon from "@mui/icons-material/Edit";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import React, { useContext } from "react";
import { globalContext } from "../../State";

export interface ICVText {
  text?: string;
  transform?: "none" | "uppercase";
  fontStyle?: "normal" | "italic";
  fontWeight?: "normal" | "bold";
  fontSize?:
    | "0.5rem"
    | "0.75rem"
    | "1rem"
    | "1.5rem"
    | "2rem"
    | "2.5rem"
    | "3rem";
  loadingStatus?: { loading: boolean; error: boolean };
  editCallback?: () => void;
}

const CVText: React.FC<React.PropsWithChildren<ICVText>> = (props) => {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);
  return (
    <Box>
      {props.loadingStatus?.loading || props.loadingStatus?.error ? (
        <Box display="flex" gap={2} alignItems="center">
          <Skeleton
            variant="text"
            width={"100%"}
            sx={{
              fontSize: props.fontSize ?? "1rem",
            }}
          />
          <WarningAmberIcon color="error" />
        </Box>
      ) : (
        <Box
          display="flex"
          gap={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              fontStyle: props.fontStyle ?? "normal",
              fontWeight: props.fontWeight ?? "normal",
              lineHeight: "normal",
              textTransform: props.transform ?? "none",
              fontSize: props.fontSize ?? "1rem",
            }}
          >
            {props.text ?? "YOUR NAME"}
          </Typography>
          {globalState.cvViewMode === "edit" && props.editCallback && (
            <IconButton onClick={props.editCallback}>
              <EditIcon />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CVText;
