import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { ICVUserSummary } from "../../db";

export interface ICVSummaryEdit {
  open: boolean;
  cancel: () => void;
  confirm: (item: ICVUserSummary) => void;
  item?: ICVUserSummary;
}

const CVSummaryEdit: React.FC<React.PropsWithChildren<ICVSummaryEdit>> = (
  props
) => {
  const [text, setText] = React.useState("");
  const textHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleCloseCancel = () => {
    if (props.item) {
      setText(props.item?.text);
    }
    props.cancel();
  };

  const handleCloseSave = () => {
    if (props.item) {
      props.confirm({
        uuid: props.item.uuid,
        text: text,
      });
    }
  };

  useEffect(() => {
    if (props.item) {
      setText(props.item?.text);
    }
  }, [JSON.stringify(props.item)]);

  return (
    <div>
      <Dialog open={props.open} onClose={handleCloseCancel}>
        <DialogTitle>Edit Summary Infos</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit your summary details and save your changes!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="summary"
            label="Summary Text"
            type="text"
            fullWidth
            multiline
            maxRows={4}
            variant="standard"
            value={text}
            onChange={textHandleChange}
            placeholder="Set your summary text..."
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCloseSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CVSummaryEdit;
