import EditIcon from "@mui/icons-material/Edit";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useContext } from "react";
import { globalContext } from "../../State";

export interface ICVHeader {
  type: "primary" | "secondary";
  title?: string;
  fontSize?: "1rem" | "1.5rem" | "2rem" | "2.5rem" | "3rem";
  fontWeight?: "normal" | "bold";
  loading?: boolean;
  accentColor?: string;
  loadingStatus?: { loading: boolean; error: boolean };
  editCallback?: () => void;
}

const CVHeader: React.FC<React.PropsWithChildren<ICVHeader>> = (props) => {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);

  const borderTop = props.type === "primary" ? 2 : 0;
  const borderBottom = props.type === "primary" ? 2 : 0;
  return (
    <Box
      borderTop={borderTop}
      borderBottom={borderBottom}
      borderColor={props.accentColor ?? grey[600]}
      padding="0.25rem 0"
    >
      {props.loadingStatus?.loading || props.loadingStatus?.error ? (
        <Box display="flex" gap={2} alignItems="center">
          <Skeleton
            variant="text"
            width={"100%"}
            sx={{
              fontSize: props.fontSize ?? "1rem",
            }}
          />
          <WarningAmberIcon color="error" />
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            sx={{
              textTransform: "uppercase",
              fontSize: props.fontSize ?? "1rem",
              fontWeight: props.fontWeight ?? "normal",
              letterSpacing: "0.25rem",
            }}
          >
            {props.title}
          </Typography>
          {globalState.cvViewMode === "edit" && props.editCallback && (
            <IconButton onClick={props.editCallback}>
              <EditIcon />
            </IconButton>
          )}
        </Box>
      )}

      {props.type === "secondary" && (
        <Box
          borderBottom={2}
          borderColor={props.accentColor ?? grey[600]}
          width="25%"
          paddingTop={1}
        ></Box>
      )}
    </Box>
  );
};

export default CVHeader;
