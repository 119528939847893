import {
  Box,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import CCExpandCards from "../../Custom/ExpandingCards/ExpandCards";
import { globalContext } from "../../State";
import "./Portfolio-Components.scss";

export interface IPortfolioComponents {}

const PortfolioComponents: React.FC<
  React.PropsWithChildren<IPortfolioComponents>
> = (props) => {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);
  useEffect(
    () => dispatch({ type: "TITLE", payload: "Custom Components" }),
    []
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box display="flex" flexDirection="row" gap={0} padding={2} height="100%">
      <Paper
        sx={{
          width: 320,
          overflow: "auto",
        }}
      >
        <MenuList dense>
          <MenuItem disabled>Components</MenuItem>
          <MenuItem>
            <ListItemText>Expanding Cards</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <Divider></Divider>

          <MenuItem disabled>Layouts</MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <Divider></Divider>

          <MenuItem disabled>Others</MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>...</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
      <Grid container overflow="auto">
        <CCExpandCards></CCExpandCards>
      </Grid>
    </Box>
  );
};

export default PortfolioComponents;
