import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, IconButton, Skeleton } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useContext, useState } from "react";
import { ICVUserSummary } from "../../db";
import { globalContext } from "../../State";
import CVAlert from "./CV-Alert";
import CVHeader from "./CV-Header";
import CVText from "./CV-Text";

export interface ICVSummary {
  data?: ICVUserSummary[];
  loadingStatus?: { loading: boolean; error: boolean };
  accentColor?: string;
  addCallback?: () => void;
  removeCallback?: (uuid: string) => void;
  editCallback?: (uuid: string) => void;
}

const CVSummary: React.FC<React.PropsWithChildren<ICVSummary>> = (props) => {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);

  const [alertOpen, setAlertOpen] = useState(false);

  const [removeItem, setRemoveItem] = useState<string>();

  return props.loadingStatus?.loading || props.loadingStatus?.error ? (
    <Box display="flex" gap={2} alignItems="center">
      <Skeleton
        variant="text"
        width={"100%"}
        sx={{
          fontSize: "1rem",
          flexGrow: 1,
        }}
      />
      <WarningAmberIcon color="error" />
    </Box>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      paddingTop={2}
      style={{ pageBreakInside: "avoid" }}
    >
      <CVHeader
        type="primary"
        title="Summary"
        accentColor={props.accentColor}
      ></CVHeader>
      {props.data?.map((item, i) => (
        <Box key={"cvcontent" + i} display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <CVText
              text={
                item.text.length === 0
                  ? "Hier kannst du eine Beschreibung hinzufügen."
                  : item.text
              }
            ></CVText>
            {globalState.cvViewMode === "edit" && (
              <Box display="flex" alignItems="flex-start">
                <IconButton onClick={() => props.editCallback?.(item.uuid)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setRemoveItem(item.uuid);
                    setAlertOpen(true);
                  }}
                >
                  <RemoveCircleIcon color="error" />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box
            borderBottom={2}
            borderColor={grey[600]}
            width="25%"
            paddingTop={1}
          ></Box>
        </Box>
      ))}

      {globalState.cvViewMode === "edit" && (
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <IconButton onClick={props.addCallback}>
            <AddCircleIcon />
          </IconButton>
        </Box>
      )}

      <CVAlert
        open={alertOpen}
        cancel={() => {
          setAlertOpen(false);
        }}
        confirm={() => {
          if (removeItem) props.removeCallback?.(removeItem);
          setAlertOpen(false);
        }}
      ></CVAlert>
    </Box>
  );
};

export default CVSummary;
