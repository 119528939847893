import { Container, Paper } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { globalContext } from "../../State";

export interface IPortfolioNoMatch {}

const PortfolioNoMatch: React.FC<React.PropsWithChildren<IPortfolioNoMatch>> = (
  props
) => {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);
  useEffect(
    () => dispatch({ type: "TITLE", payload: "Content not found" }),
    []
  );

  return (
    <Container
      sx={{
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Paper elevation={6} style={{ padding: "1rem" }}>
        Sorry, the content you are looking for is not available. Please contact
        us if you need help!
      </Paper>
    </Container>
  );
};

export default PortfolioNoMatch;
