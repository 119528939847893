import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

export interface ICVIconText {
  icon: JSX.Element;
  text?: string;
  transform?: "none" | "uppercase";
  fontStyle?: "normal" | "italic";
  fontWeight?: "normal" | "bold";
  fontSize?: "1rem" | "1.5rem" | "2rem" | "2.5rem" | "3rem";
  accentColor?: string;
  loadingStatus?: { loading: boolean; error: boolean };
}

const CVIconText: React.FC<React.PropsWithChildren<ICVIconText>> = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="1rem"
      alignItems="center"
      flexGrow={1}
    >
      {props.loadingStatus?.loading || props.loadingStatus?.error ? (
        <Box display="flex" gap={2} alignItems="center" flexGrow={1}>
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
          <Skeleton variant="text" sx={{ fontSize: "1rem", flexGrow: 1 }} />
          <WarningAmberIcon color="error" />
        </Box>
      ) : (
        <>
          <Avatar sx={{ background: props.accentColor ?? grey[600] }}>
            {props.icon}
          </Avatar>
          <Typography
            sx={{
              textTransform: "none",
              fontSize: props.fontSize ?? "0.75rem",
              fontWeight: props.fontWeight ?? "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {props.text ?? props.children}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default CVIconText;
