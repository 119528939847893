import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  CardMedia,
  IconButton,
  Paper,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { globalContext } from "../../State";

export interface ICVImage {
  src?: string;
  loading?: boolean;
}

const CVImage: React.FC<React.PropsWithChildren<ICVImage>> = (props) => {
  // APP STATE
  const { globalState } = useContext(globalContext);

  // ELEMENT
  const [alignment, setAlignment] = React.useState<string>("show");
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  // IMAGE
  const [showImage, setShowImage] = useState(false);
  const inputImage = React.useRef<any>(null);
  const uploadedImage = React.useRef<any>(null);
  const handleImageUpload = (e: any) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      if (current) {
        current.file = file;
        reader.onload = (e) => {
          current.src = e.target?.result;
        };
      }
      reader.readAsDataURL(file);
      setShowImage(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display:
            globalState.cvViewMode === "edit" || alignment === "show"
              ? "block"
              : "none",
        }}
        paddingTop={2}
      >
        {props.loading ? (
          <Skeleton variant="rectangular" width={"100%"} height={300} />
        ) : (
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            width="100%"
            height="260px"
            position="relative"
            overflow="hidden"
            borderRadius={2}
          >
            <IconButton
              component="label"
              sx={{ width: "100%", height: "100%", padding: 0 }}
            >
              {!showImage && (
                <CameraAltOutlinedIcon
                  sx={{ height: "50%", width: "50%" }}
                ></CameraAltOutlinedIcon>
              )}

              <CardMedia
                component="img"
                height="300"
                ref={uploadedImage}
                sx={{
                  display: !showImage ? "none" : "block",
                }}
              />

              <input
                ref={inputImage}
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={handleImageUpload}
              />
            </IconButton>

            {globalState.cvViewMode === "edit" && (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                  }}
                >
                  <Paper elevation={3}>
                    <ToggleButtonGroup
                      value={alignment}
                      exclusive
                      onChange={handleAlignment}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="show" aria-label="show">
                        <Tooltip title="Show image">
                          <VisibilityIcon />
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value="hide" aria-label="off">
                        <Tooltip title="Hide image">
                          <VisibilityOffIcon />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Paper>
                </Box>

                {showImage && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  >
                    <Paper elevation={3}>
                      <Button
                        sx={{ height: "48px" }}
                        onClick={() => {
                          inputImage.current.value = null;
                          setShowImage(false);
                        }}
                      >
                        <RemoveCircleIcon color="error" />
                      </Button>
                    </Paper>
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default CVImage;
