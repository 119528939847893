import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, IconButton, Skeleton } from "@mui/material";
import React, { useContext, useState } from "react";
import { ICVUserEducation } from "../../db";
import { globalContext } from "../../State";
import CVAlert from "./CV-Alert";
import CVHeader from "./CV-Header";
import CVTitle from "./CV-Title";

interface ICVEducation {
  items?: ICVUserEducation[];
  accentColor?: string;
  loadingStatus?: { loading: boolean; error: boolean };
  addCallback?: () => void;
  removeCallback?: (uuid: string) => void;
  editCallback?: (uuid: string) => void;
}

const CVEducation: React.FC<React.PropsWithChildren<ICVEducation>> = (
  props
) => {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);

  const [alertOpen, setAlertOpen] = useState(false);

  const [removeItem, setRemoveItem] = useState<string>();

  return props.loadingStatus?.loading || props.loadingStatus?.error ? (
    <Box display="flex" gap={2} alignItems="center">
      <Skeleton
        variant="text"
        width={"100%"}
        sx={{
          fontSize: "1rem",
          flexGrow: 1,
        }}
      />
      <WarningAmberIcon color="error" />
    </Box>
  ) : (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        paddingTop={2}
        style={{ pageBreakInside: "avoid" }}
      >
        <CVHeader
          type="secondary"
          title="Education"
          fontWeight="bold"
          accentColor={props.accentColor}
        ></CVHeader>
        {props.items?.map((item, i) => (
          <Box display="flex" justifyContent="space-between">
            <CVTitle
              key={i}
              text={item.title}
              subText={item.date + " || " + item.location}
            ></CVTitle>

            {globalState.cvViewMode === "edit" && (
              <Box display="flex">
                <IconButton onClick={() => props.editCallback?.(item.uuid)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setRemoveItem(item.uuid);
                    setAlertOpen(true);
                  }}
                >
                  <RemoveCircleIcon color="error" />
                </IconButton>

                <CVAlert
                  open={alertOpen}
                  cancel={() => {
                    setAlertOpen(false);
                  }}
                  confirm={() => {
                    if (removeItem) props.removeCallback?.(removeItem);
                    setAlertOpen(false);
                  }}
                ></CVAlert>
              </Box>
            )}
          </Box>
        ))}
      </Box>

      {globalState.cvViewMode === "edit" && (
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <IconButton onClick={props.addCallback}>
            <AddCircleIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CVEducation;
