import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import PortfolioAppBar from "../Components/AppBar/Portfolio-AppBar";
import PortfolioFooter from "../Components/Footer/Portfolio-Footer";
import PortfolioWave from "../Components/Wave/wave.component";
import "./Portfolio-Layout.scss";

export interface IPortfolioLayout {}

const PortfolioLayout: React.FC<React.PropsWithChildren<IPortfolioLayout>> = (
  props
) => {
  return (
    <Box className="portfolio-layout">
      <header>
        <PortfolioAppBar title="Test"></PortfolioAppBar>
      </header>
      <main>
        <Outlet />
        <PortfolioWave></PortfolioWave>
      </main>
      <footer>
        <PortfolioFooter></PortfolioFooter>
      </footer>
    </Box>
  );
};

export default PortfolioLayout;
