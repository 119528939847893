import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TwitterIcon from "@mui/icons-material/Twitter";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Link, Skeleton } from "@mui/material";
import React from "react";
import { ICVUserContact } from "../../db";
import CVHeader from "./CV-Header";
import CVIconText from "./CV-IconText";

interface ICVContact {
  data?: ICVUserContact;
  accentColor?: string;
  loadingStatus?: { loading: boolean; error: boolean };
  editCallback?: () => void;
}

const CVContact: React.FC<React.PropsWithChildren<ICVContact>> = (props) => {
  return props.loadingStatus?.loading || props.loadingStatus?.error ? (
    <Box display="flex" gap={2} alignItems="center">
      <Skeleton
        variant="text"
        width={"100%"}
        sx={{
          fontSize: "1rem",
        }}
      />
      <WarningAmberIcon color="error" />
    </Box>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      paddingTop={2}
      style={{ pageBreakInside: "avoid" }}
    >
      <CVHeader
        title="Contact"
        type="secondary"
        fontWeight="bold"
        accentColor={props.accentColor}
        editCallback={props.editCallback}
      ></CVHeader>
      {props.data?.phone.enabled && (
        <CVIconText
          icon={<PhoneIphoneIcon />}
          accentColor={props.accentColor}
          loadingStatus={props.loadingStatus}
        >
          <Link href={"tel:" + props.data?.phone.value}>
            {props.data?.phone.value}
          </Link>
        </CVIconText>
      )}

      {props.data?.email.enabled && (
        <CVIconText
          icon={<EmailIcon />}
          accentColor={props.accentColor}
          loadingStatus={props.loadingStatus}
        >
          <Link href={"mailto:" + props.data?.email.value}>
            {props.data?.email.value}
          </Link>
        </CVIconText>
      )}

      {props.data?.linkedin.enabled && (
        <CVIconText
          icon={<LinkedInIcon />}
          accentColor={props.accentColor}
          loadingStatus={props.loadingStatus}
        >
          <Link href={props.data?.linkedin.value}>
            {props.data?.linkedin.value}
          </Link>
        </CVIconText>
      )}

      {props.data?.facebook.enabled && (
        <CVIconText
          icon={<FacebookIcon />}
          accentColor={props.accentColor}
          loadingStatus={props.loadingStatus}
        >
          <Link href={props.data?.facebook.value}>
            {props.data?.facebook.value}
          </Link>
        </CVIconText>
      )}

      {props.data?.instagram.enabled && (
        <CVIconText
          icon={<InstagramIcon />}
          accentColor={props.accentColor}
          loadingStatus={props.loadingStatus}
        >
          <Link href={props.data?.instagram.value}>
            {props.data?.instagram.value}
          </Link>
        </CVIconText>
      )}

      {props.data?.twitter.enabled && (
        <CVIconText
          icon={<TwitterIcon />}
          accentColor={props.accentColor}
          loadingStatus={props.loadingStatus}
        >
          <Link href={props.data?.twitter.value}>
            {props.data?.twitter.value}
          </Link>
        </CVIconText>
      )}

      {props.data?.location.enabled && (
        <CVIconText
          icon={<LocationOnIcon />}
          accentColor={props.accentColor}
          loadingStatus={props.loadingStatus}
        >
          <Link
            href={
              "https://maps.google.com/maps?q=" +
              props.data?.location.value.replace(",", "").replace(" ", "+")
            }
            target="_blank"
          >
            {props.data?.location.value}
          </Link>
        </CVIconText>
      )}
    </Box>
  );
};

export default CVContact;
