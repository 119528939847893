import DarkModeIcon from "@mui/icons-material/DarkMode";
import EditIcon from "@mui/icons-material/Edit";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LightModeIcon from "@mui/icons-material/LightMode";
import MenuIcon from "@mui/icons-material/Menu";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Alert,
  AppBar,
  Box,
  IconButton,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { globalContext } from "../../State";

export interface IPortfolioAppBar {
  title: string;
}

const PortfolioAppBar: React.FC<React.PropsWithChildren<IPortfolioAppBar>> = (
  props
) => {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);
  // LOCATION
  let location = useLocation();
  useEffect(() => {}, [location]);

  const [alignment, setAlignment] = React.useState<string | null>(
    globalState.cvViewMode
  );

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment === "pdf") {
      setUnderConstructionOpen(true);
    } else if (newAlignment !== null) {
      setAlignment(newAlignment);
      dispatch({ type: "CV_VIEW_MODE", payload: newAlignment });
    }
  };

  // THEME MODE
  const [theme, setTheme] = React.useState<string | null>(
    globalState.themeMode
  );
  const handleTheme = (
    event: React.MouseEvent<HTMLElement>,
    newTheme: string | null
  ) => {
    if (newTheme !== null) {
      setTheme(newTheme);
      dispatch({ type: "THEME_MODE", payload: newTheme });
    }
  };

  // FEATURE under construction warning alert
  const [underConstructionOpen, setUnderConstructionOpen] =
    React.useState(false);
  const underConstructionHandleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setUnderConstructionOpen(false);
  };

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {globalState.home ? (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <IconButton
                href="/"
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <HomeOutlinedIcon></HomeOutlinedIcon>
              </IconButton>
            )}

            <Typography
              variant="h6"
              component="div"
              textTransform="uppercase"
              sx={{ flexGrow: 1 }}
            >
              {globalState.title}
            </Typography>
            <ToggleButtonGroup
              value={theme}
              exclusive
              onChange={handleTheme}
              aria-label="theme mode"
              sx={{ paddingX: 2 }}
            >
              <ToggleButton value="light" aria-label="light">
                <Tooltip title="Light mode">
                  <LightModeIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="dark" aria-label="dark">
                <Tooltip title="Dark mode">
                  <DarkModeIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>

            {location.pathname === "/cv" && (
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton value="preview" aria-label="preview">
                  <Tooltip title="Preview">
                    <VisibilityIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="edit" aria-label="edit">
                  <Tooltip title="Edit">
                    <EditIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="pdf" aria-label="pdf">
                  <Tooltip title="Generate PDF">
                    <PictureAsPdfIcon />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </Toolbar>
        </AppBar>
      </Box>

      <Snackbar
        open={underConstructionOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={underConstructionHandleClose}
      >
        <Alert
          onClose={underConstructionHandleClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          Feature is comming soon! You can use the print feature of your
          browser. Be aware that only Chrome is supported.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PortfolioAppBar;
