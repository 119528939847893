import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, IconButton, Skeleton } from "@mui/material";
import React, { useContext, useState } from "react";
import { ICVUserJobs } from "../../db";
import { globalContext } from "../../State";
import CVAlert from "./CV-Alert";
import CVHeader from "./CV-Header";
import CVText from "./CV-Text";
import CVTitle from "./CV-Title";

export interface ICVJobs {
  data?: ICVUserJobs[];
  accentColor?: string;
  loadingStatus?: { loading: boolean; error: boolean };
  addCallback?: () => void;
  removeCallback?: (uuid: string) => void;
  editCallback?: (uuid: string) => void;
}

const CVJobs: React.FC<React.PropsWithChildren<ICVJobs>> = (props) => {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);

  const [alertOpen, setAlertOpen] = useState(false);

  const [removeItem, setRemoveItem] = useState<string>();

  return props.loadingStatus?.loading || props.loadingStatus?.error ? (
    <Box display="flex" gap={2} alignItems="center">
      <Skeleton
        variant="text"
        width={"100%"}
        sx={{
          fontSize: "1rem",
          flexGrow: 1,
        }}
      />
      <WarningAmberIcon color="error" />
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" gap={1} paddingTop={2}>
      <CVHeader
        type="primary"
        title="Job Experience"
        accentColor={props.accentColor}
      ></CVHeader>
      {props.data?.map((item, i) => (
        <Box
          key={"cvjob" + i}
          display="flex"
          flexDirection="column"
          gap={1}
          paddingTop={2}
          style={{ pageBreakInside: "avoid" }}
        >
          <Box display="flex" justifyContent="space-between">
            <CVTitle
              text={
                item.date.length === 0
                  ? "Hier kannst du ein Projektdatum eingeben."
                  : item.date
              }
              subText={
                item.project.length === 0
                  ? "Hier kannst du eine kurze Projektinfo eingeben."
                  : item.project
              }
              accentColor={props.accentColor}
            ></CVTitle>

            {globalState.cvViewMode === "edit" && (
              <Box display="flex">
                <IconButton onClick={() => props.editCallback?.(item.uuid)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setRemoveItem(item.uuid);
                    setAlertOpen(true);
                  }}
                >
                  <RemoveCircleIcon color="error" />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box paddingLeft={2}>
            <CVText
              text="Description:"
              fontSize="0.75rem"
              fontWeight="bold"
              transform="uppercase"
            ></CVText>
            <CVText
              text={
                item.description.length === 0
                  ? "Füge eine Projektbeschreibung hinzu."
                  : item.description
              }
              fontSize="0.75rem"
            ></CVText>
          </Box>
          <Box paddingLeft={2}>
            <CVText
              text="Tasks:"
              fontSize="0.75rem"
              fontWeight="bold"
              transform="uppercase"
            ></CVText>
            <CVText
              text={
                item.tasks.length === 0
                  ? "Beschreiben hier deine Aufgaben im Projekt."
                  : item.tasks
              }
              fontSize="0.75rem"
            ></CVText>
          </Box>
        </Box>
      ))}
      {globalState.cvViewMode === "edit" && (
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <IconButton onClick={props.addCallback}>
            <AddCircleIcon />
          </IconButton>
        </Box>
      )}

      <CVAlert
        open={alertOpen}
        cancel={() => {
          setAlertOpen(false);
        }}
        confirm={() => {
          if (removeItem) props.removeCallback?.(removeItem);
          setAlertOpen(false);
        }}
      ></CVAlert>
    </Box>
  );
};

export default CVJobs;
