import { Box, Skeleton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

export interface ICVTitle {
  text: string;
  subText?: string;
  transform?: "none" | "uppercase";
  fontStyle?: "normal" | "italic";
  fontWeight?: "normal" | "bold";
  fontSize?: "1rem" | "1.5rem" | "2rem" | "2.5rem" | "3rem";
  accentColor?: string;
  loading?: boolean;
}

const CVTitle: React.FC<React.PropsWithChildren<ICVTitle>> = (props) => {
  return (
    <Box>
      {props.loading ? (
        <Skeleton
          variant="text"
          width={"100%"}
          sx={{ fontSize: props.fontSize ?? "1rem" }}
        />
      ) : (
        <>
          <Typography
            fontSize="0.75rem"
            fontStyle="normal"
            fontWeight="bold"
            lineHeight="normal"
            textTransform="uppercase"
          >
            {props.text}
          </Typography>
          {props.subText && (
            <Typography
              fontSize="0.75rem"
              fontStyle="normal"
              fontWeight="normal"
              lineHeight="normal"
              textTransform="uppercase"
            >
              {props.subText}
            </Typography>
          )}
        </>
      )}
      <Box
        borderBottom={2}
        borderColor={props.accentColor ?? grey[600]}
        width="25%"
        paddingTop={1}
      ></Box>
    </Box>
  );
};

export default CVTitle;
