import { ActionType, GlobalStateInterface } from "./Types";

const Reducer = (state: GlobalStateInterface, action: ActionType): any => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        loggedUser: action.payload,
      };
    case "AUTHENTICATE_USER":
      return {
        ...state,
        isUserAuthenticated: action.payload,
      };
    case "SET_PERSISTENCE":
      return {
        ...state,
        persistenceType: action.payload,
      };
    case "CV_VIEW_MODE":
      return {
        ...state,
        cvViewMode: action.payload,
      };
    case "HOME":
      return {
        ...state,
        home: action.payload,
      };
    case "TITLE":
      return {
        ...state,
        title: action.payload,
      };
      case "THEME_MODE":
        return {
          ...state,
          themeMode: action.payload,
        };
    default:
      return state;
  }
};

export default Reducer;
