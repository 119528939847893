import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import PortfolioLayout from "./Layout/Portfolio-Layout";
import PortfolioComponents from "./Pages/CustomComponents/Portfolio-Components";
import PortfolioCV from "./Pages/CV/Portfolio-CV";
import PortfolioHome from "./Pages/Home/Portfolio-Home";
import PortfolioNoMatch from "./Pages/NoMatch/Portfolio-NoMatch";
import { globalContext } from "./State";

function App() {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);
  // LOCATION
  let location = useLocation();
  useEffect(() => {
    console.log(location);

    dispatch({ type: "HOME", payload: location.pathname === "/" });
  }, [location]);

  const theme = React.useMemo(() => {
    document.body.style.backgroundColor =
      globalState.themeMode === "light" ? "#fff" : "#242424";
    return createTheme({
      palette: {
        mode: globalState.themeMode,
      },
    });
  }, [globalState.themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<PortfolioLayout />}>
          <Route index element={<PortfolioHome />} />
          <Route path="cv" element={<PortfolioCV />} />
          <Route path="cc" element={<PortfolioComponents />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<PortfolioNoMatch />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
