import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

interface ICVAlert {
  open: boolean;
  cancel: () => void;
  confirm: () => void;
}

const CVAlert: React.FC<React.PropsWithChildren<ICVAlert>> = (props) => {
  const handleClose = () => {
    props.cancel();
  };

  const handleCloseOK = () => {
    props.confirm();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Löschen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Möchten Sie den Eintrag löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="contained" onClick={handleCloseOK} autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CVAlert;
