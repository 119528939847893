import AddCircleIcon from "@mui/icons-material/AddCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Chip, Skeleton } from "@mui/material";
import React, { useContext, useState } from "react";
import { ICVUserSkill } from "../../db";
import { globalContext } from "../../State";
import CVAlert from "./CV-Alert";
import CVHeader from "./CV-Header";

export interface ICVSkills {
  skills?: ICVUserSkill[];
  loadingStatus?: { loading: boolean; error: boolean };
  accentColor?: string;
  addCallback?: () => void;
  editCallback?: (uuid: string) => void;
  removeCallback?: (uuid: string) => void;
}

const CVSkills: React.FC<React.PropsWithChildren<ICVSkills>> = (props) => {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);

  const [alertOpen, setAlertOpen] = useState(false);

  const [removeItem, setRemoveItem] = useState<string>();

  const handleDelete = (uuid: string) => {
    setRemoveItem(uuid);
    setAlertOpen(true);
  };
  return props.loadingStatus?.loading || props.loadingStatus?.error ? (
    <Box display="flex" gap={2} alignItems="center">
      <Skeleton
        variant="text"
        width={"100%"}
        sx={{
          fontSize: "1rem",
          flexGrow: 1,
        }}
      />
      <WarningAmberIcon color="error" />
    </Box>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      paddingTop={2}
      style={{ pageBreakInside: "avoid" }}
    >
      <CVHeader
        type="secondary"
        title="Skills"
        fontWeight="bold"
        accentColor={props.accentColor}
      ></CVHeader>
      <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
        {props.skills?.map(function (skill, i) {
          return (
            <Chip
              label={skill.text === "" ? "Skill hier bearbeiten!" : skill.text}
              variant="outlined"
              key={i}
              onClick={
                globalState.cvViewMode === "edit"
                  ? () => props.editCallback?.(skill.uuid)
                  : undefined
              }
              onDelete={
                globalState.cvViewMode === "edit"
                  ? () => handleDelete(skill.uuid)
                  : undefined
              }
            />
          );
        })}
        {globalState.cvViewMode === "edit" && (
          <Chip
            label="Add more"
            variant="outlined"
            icon={<AddCircleIcon />}
            clickable
            onClick={props.addCallback}
          />
        )}
      </Box>
      <CVAlert
        open={alertOpen}
        cancel={() => {
          setAlertOpen(false);
        }}
        confirm={() => {
          if (removeItem) props.removeCallback?.(removeItem);
          setAlertOpen(false);
        }}
      ></CVAlert>
    </Box>
  );
};

export default CVSkills;
