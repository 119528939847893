import { Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import PortfolioCard from "../../Components/Card/Portfolio-Card";
import { globalContext } from "../../State";
import "./Portfolio-Home.scss";

export interface IPortfolioHome {}

const PortfolioHome: React.FC<React.PropsWithChildren<IPortfolioHome>> = (
  props
) => {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);
  useEffect(() => dispatch({ type: "TITLE", payload: "Home" }), []);

  return (
    <>
      <Grid container spacing={4} padding={{ sm: 1, md: 2, lg: 4 }}>
        <Grid item xs={12} sm={6} md={3} display="flex">
          <PortfolioCard
            title="Create your curriculum vitae"
            link="/cv"
            action1={{ title: "Gregor Kurpiel (CEO)", link: "/cv" }}
          ></PortfolioCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3} display="flex">
          <PortfolioCard title="Web" link="/cc"></PortfolioCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3} display="flex">
          <PortfolioCard title="Native" link="/cv"></PortfolioCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3} display="flex">
          <PortfolioCard title="Cross Platform" link="/unknown"></PortfolioCard>
        </Grid>
      </Grid>
    </>
  );
};

export default PortfolioHome;
