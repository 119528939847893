import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import card from "./../../Resources/Images/cards/card1.jpg";

export interface IPortfolioCardAction {
  title: string;
  link: string;
}

export interface IPortfolioCard {
  title: string;
  link: string;
  action1?: IPortfolioCardAction;
  action2?: IPortfolioCardAction;
}

const PortfolioCard: React.FC<React.PropsWithChildren<IPortfolioCard>> = (
  props
) => {
  let navigate = useNavigate();

  function goTo() {
    navigate(props.link);
  }
  return (
    <Card>
      <CardActionArea onClick={goTo}>
        <CardMedia
          component="img"
          height="140"
          image={card}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {props.action1 && (
          <Button size="small" color="primary">
            {props.action1.title}
          </Button>
        )}
        {props.action2 && (
          <Button size="small" color="primary">
            {props.action2.title}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default PortfolioCard;
