import React, { useState } from "react";
import androidImg from "./../../Resources/Images/platforms/android_1920.jpg";
import angularImg from "./../../Resources/Images/platforms/angular.jpeg";
import appleImg from "./../../Resources/Images/platforms/apple_1920.jpg";
import crossImg from "./../../Resources/Images/platforms/cross_platform.jpeg";
import reactImg from "./../../Resources/Images/platforms/react.jpeg";
import "./ExpandCards.scss";

export interface ICCExpandCards {}

const CCExpandCards: React.FC<React.PropsWithChildren<ICCExpandCards>> = (
  props
) => {
  const [activePanel, setActivePanel] = useState(0);

  return (
    <>
      <div className="container">
        <div
          onClick={() => setActivePanel(0)}
          className={`panel ${activePanel === 0 ? "active" : ""}`}
          style={{
            backgroundImage: `url(${androidImg})`,
          }}
        >
          <h3>Google Android</h3>
        </div>
        <div
          onClick={() => setActivePanel(1)}
          className={`panel ${activePanel === 1 ? "active" : ""}`}
          style={{
            backgroundImage: `url(${appleImg})`,
          }}
        >
          <h3>Apple iOS</h3>
        </div>
        <div
          onClick={() => setActivePanel(2)}
          className={`panel ${activePanel === 2 ? "active" : ""}`}
          style={{
            backgroundImage: `url(${angularImg})`,
          }}
        >
          <h3>Angular JS</h3>
        </div>
        <div
          onClick={() => setActivePanel(3)}
          className={`panel ${activePanel === 3 ? "active" : ""}`}
          style={{
            backgroundImage: `url(${reactImg})`,
          }}
        >
          <h3>React JS</h3>
        </div>
        <div
          onClick={() => setActivePanel(4)}
          className={`panel ${activePanel === 4 ? "active" : ""}`}
          style={{
            backgroundImage: `url(${crossImg})`,
          }}
        >
          <h3>Cross Platform</h3>
        </div>
      </div>
      <div className="code"></div>
    </>
  );
};

export default CCExpandCards;
