import { colors, Paper, Slider, Typography } from "@mui/material";
import { yellow } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import CVContact from "../../Components/CV/CV-Contact";
import CVContactEdit from "../../Components/CV/CV-ContactEdit";
import CVEducation from "../../Components/CV/CV-Education";
import CVEducationEdit from "../../Components/CV/CV-EducationEdit";
import CVHeader from "../../Components/CV/CV-Header";
import CVImage from "../../Components/CV/CV-Image";
import CVJobs from "../../Components/CV/CV-Jobs";
import CVJobsEdit from "../../Components/CV/CV-JobsEdit";
import CVSkills from "../../Components/CV/CV-Skills";
import CVSkillsEdit from "../../Components/CV/CV-SkillsEdit";
import CVSummary from "../../Components/CV/CV-Summary";
import CVSummaryEdit from "../../Components/CV/CV-SummaryEdit";
import CVText from "../../Components/CV/CV-Text";
import CVTextEdit from "../../Components/CV/CV-TextEdit";
import useLocalStorage from "../../Data/LocalStorage";
import {
  ICVUser,
  ICVUserContact,
  ICVUserEducation,
  ICVUserJobs,
  ICVUserSkill,
  ICVUserSummary,
} from "../../db";
import { globalContext } from "../../State";
import DEFAULT_DB from "./db.json";
import "./Portfolio-CV.scss";

export interface IPortfolioCV {}

const PortfolioCV: React.FC<React.PropsWithChildren<IPortfolioCV>> = (
  props
) => {
  // APP STATE
  const { globalState, dispatch } = useContext(globalContext);
  useEffect(() => dispatch({ type: "TITLE", payload: "Lebenslauf" }), []);

  const [accentColor, setAccentColor] = useState<{
    name: string;
    value: string;
  }>({ name: "yellow", value: yellow["700"] });

  const [loadingStatus, setLoadingStatus] = useState<{
    loading: boolean;
    error: boolean;
  }>({ loading: false, error: false });
  const [items, setItems] = useState<ICVUser>();
  const [itemsUpdate, setItemsUpdate] = useState<ICVUser>();

  const [localStorageCV, setLocalStorageCV] = useLocalStorage(
    "pro-development-cv",
    DEFAULT_DB
  );

  useEffect(() => {
    setItems(localStorageCV);
  }, [localStorageCV]);

  useEffect(() => {
    if (itemsUpdate) {
      setLocalStorageCV(itemsUpdate);
    }
  }, [JSON.stringify(itemsUpdate)]);

  // useEffect(() => {
  //   setLoadingStatus({ loading: true, error: false });
  //   fetch("http://localhost:3001/user")
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         setItems(result);
  //         setLoadingStatus({ loading: false, error: false });
  //       },
  //       (error) => {
  //         setLoadingStatus({ loading: false, error: true });
  //       }
  //     );
  // }, []);

  // useEffect(() => {
  //   if (itemsUpdate) {
  //     const requestOptions = {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(items),
  //     };
  //     fetch("http://localhost:3001/user", requestOptions)
  //       .then((response) => response.json())
  //       .then((data) => console.log("GKU ...."));
  //   }
  // }, [JSON.stringify(itemsUpdate)]);

  // ==== CV-TEXT EDIT ====
  const [editTextOpen, setEditTextOpen] = React.useState(false);
  const [editText, setEditText] = React.useState("");
  const [editTextKey, setEditTextKey] = React.useState("");

  const editTextStart = (key: string) => {
    const text = items ? items[key as keyof ICVUser] : "";
    if (typeof text === "string") {
      setEditText(text);
      setEditTextKey(key);
      setEditTextOpen(true);
    }
  };
  const editTextEnd = (text: string) => {
    if (items) {
      let updateItems: ICVUser = { ...items };
      updateItems = Object.assign({ ...updateItems }, { [editTextKey]: text });
      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
    setEditTextOpen(false);
  };

  // ==== CV-CONTACT EDIT ====
  // CONTACT EDIT
  const [editContactOpen, setEditContactOpen] = React.useState(false);
  const [editContactItem, setEditContactItem] =
    React.useState<ICVUserContact>();
  const editContactStart = () => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      const filter = updateItems.contact;
      if (filter) {
        setEditContactItem(filter);
        setEditContactOpen(true);
      }
    }
  };

  const editContactEnd = (item: ICVUserContact) => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      updateItems.contact = item;
      setEditContactOpen(false);
      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // ==== CV-EDUCATION EDIT ====
  // EDUCATION ADD
  const addEducation = () => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      updateItems.education.push({
        uuid: uuidv4(),
        date: "Date of education",
        title: "Education title",
        location: "Education location",
      });
      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // EDUCATION REMOVE
  const removeEducation = (uuid: string) => {
    if (items) {
      const filter = items.education.filter((val) => val.uuid !== uuid);
      const updateItems: ICVUser = { ...items };
      updateItems.education = filter;

      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // EDUCATION EDIT
  const [editEducationOpen, setEditEducationOpen] = React.useState(false);
  const [editEducationItem, setEditEducationItem] =
    React.useState<ICVUserEducation>();
  const editEducationStart = (uuid: string) => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      const filter = updateItems.education.find((val) => val.uuid === uuid);
      if (filter) {
        setEditEducationItem(filter);
        setEditEducationOpen(true);
      }
    }
  };

  const editEducationEnd = (item: ICVUserEducation) => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      const education = updateItems.education.map((val) => {
        if (val.uuid === item.uuid) {
          return item;
        }
        return val;
      });
      updateItems.education = education;
      setEditEducationOpen(false);
      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // ==== CV-SUMMARY EDIT ====
  // SUMMARY ADD
  const addSummary = () => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      updateItems.summary.push({
        uuid: uuidv4(),
        text: "",
      });
      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // SUMMARY REMOVE
  const removeSummary = (uuid: string) => {
    if (items) {
      const filter = items.summary.filter((val) => val.uuid !== uuid);
      const updateItems: ICVUser = { ...items };
      updateItems.summary = filter;

      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // SUMMARY EDIT
  const [editSummaryOpen, setEditSummaryOpen] = React.useState(false);
  const [editSummaryItem, setEditSummaryItem] =
    React.useState<ICVUserSummary>();
  const editSummaryStart = (uuid: string) => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      const filter = updateItems.summary.find((val) => val.uuid === uuid);
      if (filter) {
        setEditSummaryItem(filter);
        setEditSummaryOpen(true);
      }
    }
  };

  const editSummaryEnd = (item: ICVUserSummary) => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      const summary = updateItems.summary.map((val) => {
        if (val.uuid === item.uuid) {
          return item;
        }
        return val;
      });
      updateItems.summary = summary;
      setEditSummaryOpen(false);
      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // ==== CV-JOBS EDIT ====
  // JOB ADD
  const addJob = () => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      updateItems.jobs.push({
        uuid: uuidv4(),
        date: "",
        description: "",
        project: "",
        tasks: "",
      });
      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // JOB REMOVE
  const removeJob = (uuid: string) => {
    if (items) {
      const filter = items.jobs.filter((val) => val.uuid !== uuid);
      const updateItems: ICVUser = { ...items };
      updateItems.jobs = filter;

      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // JOB EDIT
  const [editJobOpen, setEditJobOpen] = React.useState(false);
  const [editJobItem, setEditJobItem] = React.useState<ICVUserJobs>();
  const editJobStart = (uuid: string) => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      const filter = updateItems.jobs.find((val) => val.uuid === uuid);
      if (filter) {
        setEditJobItem(filter);
        setEditJobOpen(true);
      }
    }
  };

  const editJobEnd = (item: ICVUserJobs) => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      const jobs = updateItems.jobs.map((val) => {
        if (val.uuid === item.uuid) {
          return item;
        }
        return val;
      });
      updateItems.jobs = jobs;
      setEditJobOpen(false);
      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // ==== CV-SKILLS EDIT ===
  // SKILL ADD
  const addSkill = () => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      updateItems.skills.push({
        uuid: uuidv4(),
        text: "",
      });
      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };
  // SKILL REMOVE
  const removeSkill = (uuid: string) => {
    if (items) {
      const filter = items.skills.filter((val) => val.uuid !== uuid);
      const updateItems: ICVUser = { ...items };
      updateItems.skills = filter;

      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // SKILL EDIT
  const [editSkillOpen, setEditSkillOpen] = React.useState(false);
  const [editSkillItem, setEditSkillItem] = React.useState<ICVUserSkill>();
  const editSkillStart = (uuid: string) => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      const filter = updateItems.skills.find((val) => val.uuid === uuid);
      if (filter) {
        setEditSkillItem(filter);
        setEditSkillOpen(true);
      }
    }
  };

  const editSkillEnd = (item: ICVUserSkill) => {
    if (items) {
      const updateItems: ICVUser = { ...items };
      const skills = updateItems.skills.map((val) => {
        if (val.uuid === item.uuid) {
          return item;
        }
        return val;
      });
      updateItems.skills = skills;
      setEditSkillOpen(false);
      setItems(updateItems);
      setItemsUpdate(updateItems);
    }
  };

  // ==== ACCENT COLOR ====
  const colorList = Object.entries(colors).flatMap((color) =>
    Object.entries(color[1]).flatMap((val) => {
      return { name: color[0], value: val[1] };
    })
  );
  const defaultColorIndex = colorList.findIndex(
    (color) => color.value === accentColor.value
  );
  const [valueColorSlider, setValueColorSlider] =
    React.useState(defaultColorIndex);
  const handleChange = (event: Event, newValue: any) => {
    const val = newValue as number;
    setValueColorSlider(val);
    setAccentColor({
      name: colorList[val].name,
      value: colorList[val].value,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="1rem"
      sx={{ padding: { xs: "0.25rem", md: "1rem" } }}
    >
      {globalState.cvViewMode === "edit" && (
        <Paper
          elevation={3}
          sx={{
            padding: "1rem",
          }}
        >
          <Typography>
            Choose your accent color:{" "}
            <span style={{ textTransform: "uppercase" }}>
              {accentColor.name}
            </span>
          </Typography>
          <Slider
            aria-label="Accent color"
            onChange={handleChange}
            min={0}
            max={colorList.length - 1}
            value={valueColorSlider}
            sx={{
              color: accentColor.value,
            }}
          />
        </Paper>
      )}

      <Box flexGrow="1">
        <Paper
          elevation={3}
          className="aspect-ratio"
          sx={{
            padding: "1rem",
          }}
        >
          <div className="cv-grid">
            <div className="cv-grid-one">
              <Box display="flex" flexDirection="column" gap={2}>
                <CVImage></CVImage>

                {/* Name */}
                <CVText
                  loadingStatus={loadingStatus}
                  text={items?.name}
                  fontSize="2rem"
                  fontWeight="bold"
                  transform="uppercase"
                  editCallback={() => {
                    editTextStart("name");
                  }}
                ></CVText>

                {/* Title */}
                <CVHeader
                  type="primary"
                  loadingStatus={loadingStatus}
                  title={items?.title}
                  fontSize="1rem"
                  accentColor={accentColor.value}
                  editCallback={() => {
                    editTextStart("title");
                  }}
                ></CVHeader>

                {/* Contact */}
                <CVContact
                  loadingStatus={loadingStatus}
                  data={items?.contact}
                  accentColor={accentColor.value}
                  editCallback={() => {
                    editContactStart();
                  }}
                ></CVContact>

                {/* Group Education */}
                <CVEducation
                  loadingStatus={loadingStatus}
                  items={items?.education}
                  accentColor={accentColor.value}
                  addCallback={() => {
                    addEducation();
                  }}
                  removeCallback={(uuid: string) => {
                    removeEducation(uuid);
                  }}
                  editCallback={(uuid: string) => {
                    editEducationStart(uuid);
                  }}
                ></CVEducation>

                {/* Group Skills */}
                <CVSkills
                  loadingStatus={loadingStatus}
                  skills={items?.skills}
                  accentColor={accentColor.value}
                  addCallback={addSkill}
                  editCallback={editSkillStart}
                  removeCallback={removeSkill}
                ></CVSkills>
              </Box>
            </div>
            <div className="cv-grid-two">
              {/* Group Summary */}
              <CVSummary
                loadingStatus={loadingStatus}
                data={items?.summary}
                accentColor={accentColor.value}
                addCallback={() => {
                  addSummary();
                }}
                removeCallback={(uuid: string) => {
                  removeSummary(uuid);
                }}
                editCallback={(uuid: string) => {
                  editSummaryStart(uuid);
                }}
              ></CVSummary>

              {/* Group Jobs */}
              <CVJobs
                loadingStatus={loadingStatus}
                data={items?.jobs}
                accentColor={accentColor.value}
                addCallback={() => {
                  addJob();
                }}
                removeCallback={(uuid: string) => {
                  removeJob(uuid);
                }}
                editCallback={(uuid: string) => {
                  editJobStart(uuid);
                }}
              ></CVJobs>
            </div>
          </div>
        </Paper>
      </Box>
      {/* Dialogs */}
      <CVTextEdit
        item={editText}
        open={editTextOpen}
        closed={(item: string) => {
          editTextEnd(item);
        }}
      ></CVTextEdit>
      <CVEducationEdit
        item={editEducationItem}
        open={editEducationOpen}
        cancel={() => setEditEducationOpen(false)}
        confirm={(item: ICVUserEducation) => editEducationEnd(item)}
      ></CVEducationEdit>
      <CVSummaryEdit
        item={editSummaryItem}
        open={editSummaryOpen}
        cancel={() => setEditSummaryOpen(false)}
        confirm={(items: ICVUserSummary) => {
          editSummaryEnd(items);
        }}
      ></CVSummaryEdit>
      <CVContactEdit
        item={editContactItem}
        open={editContactOpen}
        cancel={() => setEditContactOpen(false)}
        confirm={(item: ICVUserContact) => editContactEnd(item)}
      ></CVContactEdit>
      <CVJobsEdit
        item={editJobItem}
        open={editJobOpen}
        cancel={() => setEditJobOpen(false)}
        confirm={(items: ICVUserJobs) => {
          editJobEnd(items);
        }}
      ></CVJobsEdit>
      <CVSkillsEdit
        item={editSkillItem}
        open={editSkillOpen}
        cancel={() => setEditSkillOpen(false)}
        confirm={(item: ICVUserSkill) => {
          editSkillEnd(item);
        }}
      ></CVSkillsEdit>
    </Box>
  );
};

export default PortfolioCV;
